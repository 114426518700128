import { useMemo } from "react";

export default function useRunnerDescriptions() {
  const descriptions = useMemo(() => ({
    emiel: "I grew up around music since I was a child, so it was not surprising that I started producing music 10 years ago, and that I have also been recording my own music since 5 years ago. Since 2020, I have started working with artists to take their productions to the next level with my beats. Through the many years of experience that I have taught myself, I try to achieve the best possible results together with my clients.",
    maria: "Here to help you reach your goals. Let’s create unforgettable experiences and make a lasting impact in the dynamic world of music promotion. I will combine my passion for music and my dedication to deliver results, approach every campaign with enthusiasm and positivity and finally, turn challenges into opportunities for growth.",
    lucia: "Lucia runs daily on a high intake of coffee, which helps her to smile throughout the day. Excited to get to work and listen to new cool music. ",
    rodrigo: "Rodrigo grew up listening and loving all things indie. Musician, producer and sound engineer by trait, Rodrigo has had the opportunity to both work and play along artists and musicians of all kinds. He's an overall music enthusiast and is generally described by his parents as 'a good boy'.",
    matija: "Hello, my name is Matija, and I am an ad runner. I am a sportsman, but I also like the business so this is why I joined the YouGrow team. In business is the same as In sports, you have to act immediately and effectively. Every day you have to consume knowledge, to show it to the world. With our support, you can spread your message better and faster!",
    abhishek: "Hi there my name is Abhishek (Abhi) , I am one of the Spotify campaign managers. I've been part of the music industry since 6 years and my joy is in seeing other artist's grow 😊 If you have any questions about EDM/Rap track, I am your guy !",
    luka: "Hey, my name is Luka and I am the head of TikTok and Instagram services here at YouGrow. I am a very positive person and enjoy helping people. I have been in the industry for years so if you have any questions about anything, I would love to help!",
    marino: "Hey, my name is Marino and I love meeting new artists and creators. I had been a part of this company for years and that’s why I have many tips and trick up my sleeve regarding YouTube promotions. In case you will need any assistance, I am here to help!",
    matej: "My name is Matej and I am head of YouTube promotion. I can say that I am responsible and communicative, and I always try to be in good relations with customers. My passion for promotion has already developed at a young age so I will do everything in my power to show your work to the world. With me, you are in safe hands.",
    federica: "Heyo! My name is Federica. With a deep passion for music and a flair for creative marketing, I bring a unique blend of expertise to my role as a Spotify Campaign Manager at YouGrow.  Since childhood, I have been busy playing piano, guitar, and singing. Today, as a DJ, my ability to read a crowd and create memorable experiences through music is translated into my professional work, where I consistently deliver dynamic and effective marketing solutions. I can't wait to help my clients achieve success and reach new heights in their music careers!",
    derek: "Like many of the other YouGrow campaign managers, Derek is an artist himself. Not only does he have self-released music on Spotify, but he has also played in several bands and DJ's house music. Derek originally got into music marketing to get listeners on his own music, but it soon became apparent he wanted to also help other artists reach new listeners using the same strategies.",
    tsvetina: "Hello, my name is Tsvetina and I am a Spotify campaign manager, I am an artist myself and I understand how hard it is to stand out as an independent musician in a world where thousands of songs are uploaded every day. That is why I joined the YouGrow team, so I can help many other artists like me to be heard and have the opportunities to be competitive on the market, while being independent. We all fight for a fair and better music industry!",
    jasper: "From a young age I was always making music, hitting everything with spoons to see what sounds they'd make and surrounding myself with instruments. When I got older, I took that passion and applied it to learning more about the industry, to get to know the ins and outs from everything to social media trends, sync licensing and working with artists. Now I apply all my knowledge and passion to helping artists grow and reach new audiences, it allows me to combine my passion for music with my passion for helping people, so nice to meet you! let’s work together!",
    boglarka: "Hey, my name is Boglarka and I'm a Spotify Campaign Manager and Music Curator here at YouGrow. I have a passion for music and love discovering new artists. As a musician myself, I understand how difficult it can be to gain recognition in the industry, so my goal is to help you get closer to that!",
    alejandro: "Hey! My name is Alejandro, I’m a campaign manager for YouGrow’s Spotify department. As one of YouGrow Promotions newest members, I’m very thrilled to have the ability to work in the industry I love, helping musicians of all kinds. Hope to be hearing your amazing new track soon!",
    dre: "Dre is a seasoned marketing professional with over 10 years of experience in the music industry. With a deep understanding of the industry’s inner workings and trends, he has worked with major record labels and independent artists alike to create successful marketing campaigns. Additionally, Dre brings an extensive musical background to the table, having worked as a producer and songwriter.",
    ethan: "Ethan is a musician/producer himself. He has produced multiple tracks for artists on Spotify and also is a multi-instrumentalist. Ethan got into music marketing because he has always loved involving himself with artists and trying to help them achieve their goals.",
    flemming: "Welcome! My names Flemming, and I’ve been serving the YouGrow team for over a year now.  Avid rap and overall music enthusiast, and eager to take a listen to your track! Feel free to reach out.",
    luke: "Unlike many of the other YouGrow Campaign Managers, Luke does not consider himself an artist. However, from a young age, Luke became obsessed with discovering unknown artists who were incredibly talented. Luke's goal was always to work with newer artists and help them succeed in any way possible. Through YouGrow, Luke has had the pleasure of doing exactly that.",
    martina: "Hey hey! My name is Martina and I am a TikTok/Instagram campaign manager. I have passion for digital marketing and social media. As your campaign manager I am eager to help you utilize TikTok and Instagram and promote yourself on the platforms. So, let's build that awareness, increasing the engagement and execute an effective campaign together. All that you deserve! 😉",
    matt: "I grew up in the UK but moved to the Netherlands 10 years ago. I studied International Music Management in Haarlem, I used to work as a Music Curator for music production library Epidemic Sound, and I’ve now been with YouGrow for over 2 years.",
    gijs: "Hi! My name is Gijs and I'm a Spotify campaign manager. I'm a producer & DJ myself and just finished my digital marketing studies. So I have a passion for both music & marketing, which makes this the perfect job for me :) I will listen to your tracks and make sure to pitch them to curators that fit your sound the best to maximize streams and engagement rates!",
    matthew: "I grew up in the UK but moved to the Netherlands 10 years ago. I studied International Music Management in Haarlem, I used to work as a Music Curator for music production library Epidemic Sound, and I’ve now been with YouGrow for over 2 years.",
    sem: "Hi there! My name is Sem, I am one of the Spotify Campaign managers. I’ve been in the music industry for over 10 years now, and I love helping people to reach their next goal in their music career. I’m specialized in EDM, so if you have any questions about that, definitely reach out to me!",
    sem_buist: "Hey there! My name is Sem Buist, and I’m one of the TikTok/Instagram Campaign Managers at YouGrow. I’ve been a guitarist for over 10 years now, my favorite genres are Indie rock and Hiphop. I love helping artists advance in their careers and achieve their next goal. If you have any questions about the process or your order, please reach out to me!",
    daniela: "Hi! My name is Daniela and I'm a Spotify campaign manager. I've been very passionate about music since I was little, therefore I can assure you that your song will get listened to with attention to detail and pitched to the most fitting playlists. I would consider myself very responsible and communicative so I can assure you that I will keep checking the progress of your order regularly. I'm excited to show your music to the rest of the world, let's do it together! 😊",
    denise: "I’m Denise, I am a campaign manager here at YouGrow. I am very passionate about communication so you can be sure I’ll be easy to reach and always try my best to help in any way I can.",
    kris: "Hey! My name is Kris and I’m a Spotify campaign manager. I’ve always been in and around music since a young age. I’ve played a wide range of instruments and have a passion for music in general. I am excited to start this musical journey with you and looking forward to seeing you and your projects grow 😊",
    filipa: "Hello, hello! I’m Filipa, a Spotify Campaign Manager at YouGrow. My love for music has been with me since I was young, and I’ve always been the one searching for the perfect song to fit any moment. There’s nothing quite like finding a track that perfectly captures your thoughts and feelings. I have a Master's in International Communication and am here to help you bring that same magic to your campaign!  I’m excited to work together 😊",
    rouvella: "Hey there, my name is Rouvella and I will be your campaign manager! I’m a huge fan of R&B, Rap and HipHop and have always had a love for the music industry as a whole. I look forward to working with you, to help best maximise the potential of your music. Feel free to reach out for questions or extra info :)",
    rokas: "Hello! My name is Rokas, I am 25, and I come from Lithuania. For as long as I can remember, music always played a big part in my life. Sound, rhythm, and melody have not only shaped my personal experiences but have also influenced my academic and professional path. My favorite band is Dire Straits, but my greatest passion lies in electronic music. Your music will be taken care of in my hands and I am ready to ROCK this campaign with you!",
  }), []);

  return descriptions;
}