export default function useMoreInfoDescriptions() {
  const influencer = (name, email) => ({
    'Pending pickup': `Your order has been processed in our system but it’s still waiting for one of our campaign managers to start working on your campaign. As soon as someone is available, a campaign manager will get your campaign up and running for you.`,
    Unclaimed: `Your order has been processed in our system but it’s still waiting for one of our campaign managers to start working on your campaign. As soon as someone is available, a campaign manager will get your campaign up and running for you.`,
    Submitted: `Your campaign manager has submitted your campaign. This usually takes 1-2 days. Once the ad has been approved, will be able to see all information via this order dashboard. You will also receive an email when the status of your campaign is being updated.`,
    Pitching: `We have started pitching your sound to our influencer network which means that the campaign is already active and that there are going to be videos delivered with your sound very soon. Once we have updates regarding your campaign, you will be able to see all information via this order dashboard. You will also receive an email when the status of your campaign is being updated.`,
    Completed: `Your campaign has been completed. If you have any questions or concerns, then feel free to email ${email} any time!`,
    Refunded: `Your campaign has been refunded. If you have any questions or concerns, then feel free to email ${email} any time!`,
    Running: `Your campaign has now been started. The TikTok Ads are now live which means that you are going to start seeing results or that you already are seeing results. If you have any questions or concerns, then feel free to email ${email} any time!`,
    "Insufficient": "Because there is some necessary information missing from your order, we are currently unable to continue the process. Your dedicated campaign manager will communicate the specific details through this order dashboard. You will get an email when the campaign manager has left you a message.",
    "Spotify Transfer": `Your campaign has been transferred to our Spotify service. You will be able
    to see status updates on this order dashboard. If you have any questions or concerns, then feel
    free to email ${email} any time!`,
    "Instagram Transfer": `Your campaign has been transferred to our Instagram service. If you
    have any questions or concerns, then feel free to email ${email} any time!`,
    "TikTok Transfer": `Your campaign has been transferred to our TikTok service. If you have any
    questions or concerns, then feel free to email ${email} any time!`,
    "Budget Saved": `You have saved budget for a future order. As soon as you are ready, please
    contact your campaign manager ${email} trough email so we can
    get started.`,
    "Transfer TikTok Sound Promotion": `Your campaign has been transferred to our TikTok service. If you have any
    questions or concerns, then feel free to email ${email} any time!`,
    "Transfer TikTok Video and Account Promotion": `Your campaign has been transferred to our TikTok service. If you have any
    questions or concerns, then feel free to email ${email} any time!`,
    "Transfer Youtube": `Your campaign has been transferred to our Youtube service. If you have any
    questions or concerns, then feel free to email ${email} any time!`
  });

  const youtube = (name, email) => ({
    'Pending pickup': `Your order has been processed in our system but it’s still waiting for one of our campaign managers to start working on your campaign. As soon as someone is available, a campaign manager will get your campaign up and running for you.`,
    Unclaimed: `Your order has been processed in our system but it’s still waiting for one of our campaign managers to start working on your campaign. As soon as someone is available, a campaign manager will get your campaign up and running for you.`,
    Submitted: `Your campaign manager has submitted your campaign. This usually takes 1-2
    days. Once the ad has been approved, the status of the order will change in this order
    dashboard and you will receive an email. If you have any questions or concerns, then feel free
    to email ${email} any time!`,
    Optimizing: `Good news! The ad has been approved. During the stage ‘optimizing’ we are
    collecting data before we start to run the campaign faster. We will analyse the data to tweak the
    campaign to ensure it runs in the best possible way. Once this process is done, an update will
    show in this order dashboard and you will also receive an email. If you have any questions or
    concerns, then feel free to email ${email} any time!`,
    Running: `Your campaign has been optimized and it is now running. If you have any questions
    or concerns, then feel free to email ${email} any time!`,
    Refunded: `Unfortunately we were unable to run your campaign because of restrictions. Your
    campaign manager will email you regarding the refund.`,
    "Near Complete": `Your campaign is nearly completed. You will receive a final update in the
    following days. If you have any questions or concerns, then feel free to email ${email} any time!`,
    Completed: `Your campaign has been concluded. In this order dashboard, you can see how
    many views we have delivered for your order. If you have any questions or concerns, then feel
    free to email ${email} any time!`,
    "Spotify Transfer": `Your campaign has been transferred to our Spotify service. You will be able
    to see status updates on this order dashboard. If you have any questions or concerns, then feel
    free to email ${email} any time!`,
    "Instagram Transfer": `Your campaign has been transferred to our Instagram service. If you
    have any questions or concerns, then feel free to email ${email} any time!`,
    "TikTok Transfer": `Your campaign has been transferred to our TikTok service. If you have any
    questions or concerns, then feel free to email ${email} any time!`,
    "Budget Saved": `You have saved budget for a future order. As soon as you are ready, please
    contact your campaign manager ${email} trough email so we can
    get started.`,
    "Partly Refunded": `Your campaign has been partially refunded. If you have any questions or
    concerns, then feel free to email ${email} any time!`,
    "Insufficient": "Because there is some necessary information missing from your order, we are currently unable to continue the process. Your dedicated campaign manager will communicate the specific details through this order dashboard. You will get an email when the campaign manager has left you a message.",
    "Insufficient Information": "Because there is some necessary information missing from your order, we are currently unable to continue the process. Your dedicated campaign manager will communicate the specific details through this order dashboard. You will get an email when the campaign manager has left you a message.",
    "Transfer Youtube": `Your campaign has been transferred to our Youtube service. If you have any
    questions or concerns, then feel free to email ${email} any time!`,
    "Transfer TikTok Sound Promotion": `Your campaign has been transferred to our TikTok service. If you have any
    questions or concerns, then feel free to email ${email} any time!`,
    "Transfer TikTok Video and Account Promotion": `Your campaign has been transferred to our TikTok service. If you have any
    questions or concerns, then feel free to email ${email} any time!`,
  });

  const statusInfos = (theName, email) => {
    const name = theName.charAt(0).toUpperCase() + theName.slice(1);

    return {
      Spotify: {
          Unclaimed: `Your order has been processed in our system but it’s still waiting for one of our campaign managers to start working on your campaign. As soon as someone is available, a campaign manager will get your campaign up and running for you.`,
          'Pending pickup': `Your order has been processed in our system but it’s still waiting for one of our campaign managers to start working on your campaign. As soon as someone is available, a campaign manager will get your campaign up and running for you.`,
          Pitching: `Your campaign manager ${name} started pitching your campaign to relevant playlists. Once a curator, or several curators placed your song, ${name} will add the updates here. You will receive an automated email once there is an update. In the 'Playlist overview' section you will be able to see the playlist(s) your song is being added to.`,
          Refunded: `Unfortunately we were unable to place your song in fitting playlists. Your campaign manager ${name} will email you regarding the refund.`,
          Completed: `Your song is growing! After pitching, we successfully placed your song. You can now see the growth of the song via Spotify for artists. In the 'Playlist overview' section you can see the playlist(s) your song is being added to. If you have any questions or concerns, then feel free to email ${name} any time!`,
          'Placement made': `Your song is growing! After pitching, we successfully placed your song. You can now see the growth of the song via Spotify for artists. In the 'Playlist overview' section you can see the playlist(s) your song is being added to. If you have any questions or concerns, then feel free to email ${name} any time!`,
          'Transfer Instagram': `Your campaign has been transferred to our Instagram service. A campaign manager will get started on your order as soon as possible. You will be able to see status updates on this order dashboard. If you have any questions or concerns, feel free to email ${email} any time!`,
          'Transfer TikTok': `Your campaign has been transferred to our TikTok service. A campaign manager will get started on your order as soon as possible. You will be able to see status updates on this order dashboard. If you have any questions or concerns, feel free to email ${email} any time!`,
          'Transfer Youtube': `Your campaign has been transferred to our YouTube service. A campaign manager will get started on your order as soon as possible. You will be able to see status updates on this order dashboard. If you have any questions or concerns, feel free to email ${email} any time!`,
          "Insufficient": "Because there is some necessary information missing from your order, we are currently unable to continue the process. Your dedicated campaign manager will communicate the specific details through this order dashboard. You will get an email when the campaign manager has left you a message.",
          "Insufficient Information": "Because there is some necessary information missing from your order, we are currently unable to continue the process. Your dedicated campaign manager will communicate the specific details through this order dashboard. You will get an email when the campaign manager has left you a message.",
          "Transfer TikTok Sound Promotion": `Your campaign has been transferred to our TikTok service. If you have any
          questions or concerns, then feel free to email ${email} any time!`,
          "Transfer TikTok Video and Account Promotion": `Your campaign has been transferred to our TikTok service. If you have any
          questions or concerns, then feel free to email ${email} any time!`,
          "Setting Up": `Your campaign manager has submitted your campaign. This usually takes 1-2 business days. Once the ad has been approved by Spotify, the status of the order will change in this order dashboard and you will receive an email. If you have any questions or concerns, then feel free to email your campaign manager.`,
          "Running": `Your campaign has been approved and is now running, which means that you are going to start seeing results or that you already are seeing results. If you have any questions or concerns, then feel free to email ${email} any time.`,
          "Finished": `Your campaign has been completed. If you have any questions or concerns, then feel free to email your campaign manager any time!`,
          "OnHold": `Your order has been placed on hold temporarily as we wait for more information from your end. Your dedicated campaign manager has reached out to you via email and will be waiting for your answer`
      },
      YouTube: youtube(theName, email),
      Instagram: influencer(theName, email),   
      Influencer: influencer(theName, email),   
      TikTok: {
        'Pending pickup': `Your order has been processed in our system but it’s still waiting for one of our campaign managers to start working on your campaign. As soon as someone is available, a campaign manager will get your campaign up and running for you.`,
        Unclaimed: `Your order has been processed in our system but it’s still waiting for one of our campaign managers to start working on your campaign. As soon as someone is available, a campaign manager will get your campaign up and running for you.`,
        Submitted: `Your campaign manager has submitted your campaign. This usually takes 1-2 days. Once the ad has been approved, will be able to see all information via this order dashboard. You will also receive an email when the status of your campaign is being updated.`,
        Running: `Your campaign has now been started. The TikTok Ads are now live which means that you are going to start seeing results or that you already are seeing results. If you have any questions or concerns, then feel free to email ${email} any time!`,
        "Emailing for codes": `We have sent you an email regarding the code we need to run TikTok Ads. Please respond to the email so we can start to work on your campaign as soon as possible. Please note that the email may landed in your spam folder.`,
        Refunded: `Your campaign has been refunded. If you have any questions or concerns, then feel free to email ${email} any time!`,
        Completed: `Your campaign has been concluded. In this order dashboard, you can see how
        many views we have delivered for your order. If you have any questions or concerns, then feel
        free to email ${email} any time!`,
        Pitching: "We have started pitching your sound to our influencer network which means that the campaign is already active and that there are going to be videos delivered with your sound very soon. Once we have updates regarding your campaign, you will be able to see all information via this order dashboard. You will also receive an email when the status of your campaign is being updated.",
        "Insufficient": "Because there is some necessary information missing from your order, we are currently unable to continue the process. Your dedicated campaign manager will communicate the specific details through this order dashboard. You will get an email when the campaign manager has left you a message.","Spotify Transfer": `Your campaign has been transferred to our Spotify service. You will be able
        to see status updates on this order dashboard. If you have any questions or concerns, then feel
        free to email ${email} any time!`,
        "Instagram Transfer": `Your campaign has been transferred to our Instagram service. If you
        have any questions or concerns, then feel free to email ${email} any time!`,
        "TikTok Influencer Transfer": `Your campaign has been transferred to our TikTok service. If you have any
        questions or concerns, then feel free to email ${email} any time!`,
        "Budget Saved": `You have saved budget for a future order. As soon as you are ready, please
        contact your campaign manager ${email} trough email so we can
        get started.`,
        'YouTube transfer': `Your campaign has been transferred to our YouTube service. A campaign manager will get started on your order as soon as possible. You will be able to see status updates on this order dashboard. If you have any questions or concerns, feel free to email ${email} any time!`,
        "TikTok Transfer": `Your campaign has been transferred to our TikTok service. If you have any
        questions or concerns, then feel free to email ${email} any time!`,
        "Transfer Youtube": `Your campaign has been transferred to our Youtube service. If you have any
        questions or concerns, then feel free to email ${email} any time!`,
        "Transfer TikTok Sound Promotion": `Your campaign has been transferred to our TikTok service. If you have any
        questions or concerns, then feel free to email ${email} any time!`,
        "Transfer TikTok Video and Account Promotion": `Your campaign has been transferred to our TikTok service. If you have any
        questions or concerns, then feel free to email ${email} any time!`,
        },    
    }
  };

  return statusInfos;
}





