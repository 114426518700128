import { useEffect } from "react";

const FeedbackForm = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://widget.reviews.io/modern-widgets/survey.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div
            id="reviews-io-survey-widget"
            data-widget-id="UXbg7xIjWruD5Pv1"
            data-store-name="yougrowpromo.com"
            data-campaign-id="4468"
            data-lang="en"
        />
    );
};

export default FeedbackForm;
