import { useMemo } from "react";
import { Typography, Grid, Box, useTheme, Stack } from "@mui/material";
import { string } from "prop-types";
import useResponsive from "../../../../hooks/useResponsive";
import useRunnerDescriptions from "../../../../hooks/useRunnerDescriptions";
import { checkNameForDupes, convertedName } from "../ShowMoreInfo";
import Iconify from "../../../../components/iconify";
import useRunnerAvailability from "../../../../hooks/useRunnerAvailability";
import useRunnerLocations from "../../../../hooks/useRunnerLocation";

CampaignManager.propTypes = {
  cmName: string
}

export default function CampaignManager({cmName}) {
  const runnerDescriptions = useRunnerDescriptions();
  const runnerLocations = useRunnerLocations();
  const runnerAvailability = useRunnerAvailability();
  const isOnSmallScreen = useResponsive("down", "md", "lg");
  const theme = useTheme();

  const ba = useMemo(() => ({
      position: "relative",
      "&:before": {
          content: '""',
          display: "block",
          height: "78%",
          width: "4px",
          position: "absolute",
          bottom: 0,
          left: 0,
          backgroundColor: "#1DD263"
      },
      "&:after": {
          content: '""',
          display: "block",
          height: "4px",
          width: {md: "100%", xs: "80%"},
          position: "absolute",
          bottom: 0,
          left: 0,
          backgroundColor: "#1DD263"
      },
  }));

  const days = [
    "M",
    "T",
    "W",
    "T",
    "F",
    "S",
    "S",
  ];

  return (
    <>
      {!cmName.includes('Platform') &&
        <>
          <Grid item md={12}>
            <Typography className="your-campaign-manager showmore-title">
              Your campaign manager
            </Typography>
          </Grid>
          <Box className="campaign-manager-wrapper white-box w-100 bg-neutral mt-1">
            <Grid container className="campaign-manager-box" spacing={{xs: 2, md: 10}}>
              <Grid item md={6}>
                {cmName ? 
                  <Grid container sx={!isOnSmallScreen && ba} rowGap={{xs: 2}}>
                      <Grid item xs={6} md={4}>
                          <Box sx={isOnSmallScreen ? {...ba, height: "100px"} : { height: "100%" }}>
                              <img style={{borderRadius: "5px 5px 5px 0", height: isOnSmallScreen ? "100px" : "128px", width: isOnSmallScreen ? "100px" : "128px", objectFit: "cover"}} src={runnerDescriptions[checkNameForDupes(cmName)] ? `/assets/campaign-managers/${checkNameForDupes(cmName)}.jpg` : '/assets/placeholders/profile.png'} alt="matt"/>
                          </Box>
                      </Grid>
                      <Grid item xs={12} md={8}>
                          <Stack rowGap={1} sx={{paddingLeft: {md: 1, xs: 0}}}>
                              <Box>
                                  <Typography sx={{fontSize: {xs: "17px", md: "20px"}}}>
                                      Campaign manager
                                  </Typography>
                                  <Typography sx={{fontSize: {xs: "15px", md: "17px"}}}>
                                      {cmName}
                                  </Typography>
                              </Box>
                              <Box>
                              <Typography sx={{fontSize: {xs: "17px", md: "20px"}}}>
                                Campaign manager email
                              </Typography>
                              <Typography sx={{fontSize: "17px", overflowWrap: "anywhere"}}>
                                  <a href={`mailto:${convertedName(cmName) === 'matthew' ? 'matt' : checkNameForDupes(cmName).replace('_', '.')}@yougrowpromo.com`} style={{fontWeight: 400, color: theme.palette.common.black}}>
                                  {convertedName(cmName) === 'matthew' ? 'matt' : checkNameForDupes(cmName).replace('_', '.')}@yougrowpromo.com
                                  </a>
                              </Typography>
                              </Box>
                          </Stack>
                      </Grid>
                  </Grid>
                  : <Typography variant="h5">No campaign manager found for your campaign.</Typography>
                }
                {cmName &&
                  <Typography className="mt-2 cm-location">
                    <Iconify icon="system-uicons:location" width={18}/> {runnerLocations[checkNameForDupes(cmName)]}
                  </Typography>
                }
              </Grid>
              {cmName &&
                <Grid item md={6}>
                  {runnerDescriptions[convertedName(cmName)] && 
                      <Box>
                          <Typography sx={{color: "#1DD263"}} className="showmore-title">Who is {cmName}?</Typography>
                          <Typography variant="body1">
                              {runnerDescriptions[checkNameForDupes(cmName)]}
                          </Typography>
                      </Box>
                  }
                  <Box className="runner-availability">
                    <Typography className="availability-title">
                      Availability
                    </Typography>
                    <Box className="availability-days">
                      {days.map((day, i) => (
                        <Box className={`availability-day ${runnerAvailability[checkNameForDupes(cmName)]?.includes(i) && 'available'} text-black`}>
                          {day}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Grid>
              }
            </Grid>
          </Box>
        </>
      }
    </>
  )
} 
